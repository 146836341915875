export default {
  LIVECASINO: "LIVE CASINO",
  PlayNow: "Play Now",
  LaunchGameError: "Launch game failed, please contact our Customer Service",
  Seemore: "Seemore",
  HOTGAMES: "TOP SLOT GAMES",
  moreGames: "More Games",
  Play: "PLAY",
  GameMaintenance: "Game is under maintenance. Please try again later.",
};
