import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { GamesStateInterface } from "./state";
import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";

export const getters: GetterTree<GamesStateInterface, RootState> = {
	getGameList(state): GamesListResponse[] {
		return state.gameLists.filter((x) => x.isActive == true);
	},
};
