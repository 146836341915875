import httpClient from "./HttpClient";

const getLaunchGameUrl = (vendorId: number, memberId: string, category: string, lang: string): Promise<string> => {
	const endPoint = `/api/Games/LaunchGame`;
	const params = {
		memberId: memberId,
		vendorId: vendorId,
		category: category,
		lang: lang
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameList = (vendorId: number, gamesCategory: string): Promise<string> => {
	const endPoint = `/api/Games`;
	const params = {
		vendorId: vendorId,
		gamesCategory: gamesCategory,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameCategories = (vendorId: number): Promise<string> => {
	const endPoint = `/api/Games/${vendorId}/Categories`;
	const params = {
		vendorId: vendorId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getLaunchGameUrl, getGameList, getGameCategories };
