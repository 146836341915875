<template>
  <b-container fluid class="mt-5">
    <b-container class="homePartContent pt-5 pb-3">
      <b-row>
        <b-col md="4" cols="12">
          <HomeJackpot></HomeJackpot>
        </b-col>
        <b-col md="8" cols="12">
          <div class="hotGameContainer">
            <div class="hotGameTabbar">
              <div class="hotGameTab" :class="{ active: currentGame == 'HotGames' ? true : false }">
                {{ $t("HomeHotSlot.HOTGAMES") }}
              </div>
              <div class="moreGames me-3" @click="startPlay()" v-if="isLogin">
                {{ $t("HomeHotSlot.moreGames") }}
              </div>
              <div class="moreGames me-3" v-b-modal="'login-modal'" v-else>
                {{ $t("HomeHotSlot.moreGames") }}
              </div>
            </div>
            <div class="hotGameList" v-if="isLogin && isMaintenance">
              <div class="hotGameItem" v-for="gameItem in gameList" :key="gameItem" @click="maintenancepopup">
                <div class="hotGameImg">
                  <base-image :imgSrc="hotGameImagePath + gameItem.GameImage" mode="bannerMax" :isFullUrl="false" />
                </div>
                <div class="hotGameBtn mt-2">{{ $t("HomeHotSlot.Play") }}</div>
              </div>
            </div>
            <div class="hotGameList" v-else-if="isLogin && !isMaintenance">
              <div class="hotGameItem" v-for="gameItem in gameList" :key="gameItem" @click="startPlay()">
                <div class="hotGameImg">
                  <base-image :imgSrc="hotGameImagePath + gameItem.GameImage" mode="bannerMax" :isFullUrl="false" />
                </div>
                <div class="hotGameBtn mt-2">{{ $t("HomeHotSlot.Play") }}</div>
              </div>
            </div>
            <div class="hotGameList" v-else>
              <div class="hotGameItem" v-for="gameItem in gameList" :key="gameItem" v-b-modal="'login-modal'">
                <div class="hotGameImg">
                  <base-image :imgSrc="hotGameImagePath + gameItem.GameImage" mode="bannerMax" :isFullUrl="false" />
                </div>
                <div class="hotGameBtn mt-2">{{ $t("HomeHotSlot.Play") }}</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>
<style scoped>
.hotGameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0px;
}

.hotGameTabbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.hotGameTab {
  cursor: pointer;
  color: #ffffff;
  font-size: 1.85rem;
  padding-bottom: 1rem;
}
.moreGames {
  cursor: pointer;
  color: #ffffff;
  font-size: 1.1rem;
  background: #273255;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 0.5rem 0.8rem;
}
.hotGameList {
  display: flex;
  flex-wrap: wrap;
}
.hotGameItem {
  width: 25%;
  padding: 0.8rem;
  cursor: pointer;
}
.hotGameBtn {
  background: rgba(193, 215, 251, 0.6);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 0.75rem;
  padding: 0.6rem 0px;
  text-align: center;
}

@media (max-width: 767px) {
  .hotGameItem {
    width: 33.33333%;
  }
  .hotGameTab {
    font-size: 1.5rem;
    padding-bottom: unset;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 1640px) {
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import HomeJackpot from "@/components/content/Home/HomeJackpot.vue";

export default {
  data() {
    return {
      homeSlotImage: "playtech/assets/images/Home/Sport_banner.png",
      homeSlotLogo: "playtech/assets/images/Home/cmd368.png",
      currentGame: "HotGames",
      hotGameImagePath: "playtech/assets/images/Home/HotSlotGames/",
      gameList: [
        {
          GameImage: "Ugga Bugga.jpg",
          GameName: "Ugga Bugga",
        },
        {
          GameImage: "Golden Tour.jpg",
          GameName: "Golden Tour",
        },
        {
          GameImage: "Arowanas Luck.jpg",
          GameName: "Arowanas Luck",
        },
        {
          GameImage: "Monkey and Rat.jpg",
          GameName: "Monkey and Rat",
        },
        {
          GameImage: "Fortune Day.jpg",
          GameName: "Fortune Day",
        },
        {
          GameImage: "Record Riches.jpg",
          GameName: "Record Riches",
        },
        {
          GameImage: "Funky Monkey.jpg",
          GameName: "Funky Monkey",
        },
        {
          GameImage: "Lucha Rumble.jpg",
          GameName: "Lucha Rumble",
        },
      ],
    };
  },
  components: {
    HomeJackpot,
  },
  methods: {
    async startPlay() {
      let loader = this.$loading.show();
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", { GameType: GameType.SLOTS, Lang: localStorage.getItem("locale") })
        .then((url) => {
          loader.hide();
          window.open(url, "_blank");
        })
        .catch(() => {
          loader.hide();
          let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
          this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
        });
    },
    async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		},
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    isMaintenance(){
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
  },
};
</script>
