export default {
  Home: "Home",
  SLOT: "Slots",
  LIVECASINO: "Live Casino",
  Download: "Download",
  Promotion: "Promotion",
  Rank: "Ranking",
  "Contact Us": "Contact Us",
  Language: "Language",
  Deposit: "Deposit",
  Withdrawal: "Withdrawal",
  "Change Password": "Change Password",
  Logout: "Logout",
  LogoutSuccess: "Logout Success",
  Profile: "Profile",
  "Bank Info": "Bank Info",
  Statement: "Statement",
  Referral: "Referral",
  Interchange: "Switch Me",
  GameIDInfo: "Game ID Info",
  VIP: "VIP",
};
