<template>
  <b-container fluid class="py-2 h-100 position-relative">
    <div class="h-100 pb-3">
      <BaseImage :imgSrc="logoUrl" mode="regularImg" :isFullUrl="false" fluid-grow></BaseImage>
    </div>
    <div id="jackpotContent" class="w-100 jackpotContent">2,126,888.30</div>
  </b-container>
</template>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import gsap from "gsap";
var jackpotAmount = 2126888.3;
const maxJackpotAmount = 2888888;
var rollingJackpot;

export default {
  data() {
    return {
      logoUrl: "playtech/assets/images/Home/UltimateJackpot.png",
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    checkIsLoadMemberBank() {
      return this.$store.getters["member/getOnLoadMemberBankStatus"];
    },
  },
  components: {
    BaseImage,
  },
  methods: {
    updateJackpot: function () {
      var counter = { var: jackpotAmount };
      var jackpotEl = document.getElementById("jackpotContent");
      var randomJackpot = Math.random() * 19 + 1;
      var Duration = 16 / randomJackpot;
      jackpotAmount = jackpotAmount + randomJackpot;
      if (maxJackpotAmount < jackpotAmount) {
        jackpotAmount = maxJackpotAmount;
        clearInterval(rollingJackpot);
      }

      gsap.to(counter, {
        duration: Duration,
        var: jackpotAmount,
        onUpdate: function () {
          var nwc = counter.var.numberFormat(2);
          jackpotEl.innerHTML = nwc;
        },
      });
    },
    jackpotCounter: function () {
      this.updateJackpot();
      rollingJackpot = setInterval(this.updateJackpot, 5000);
    },
  },
  mounted() {
    this.jackpotCounter();
  },
  unmounted() {
    clearInterval(rollingJackpot);
  },
};
</script>

<style scoped>
.regularImg {
  width: auto !important;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.jackpotContent {
  color: #ffffff;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: calc(10px + 1.05vw);
  line-height: 36px;
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: 30%;
  left: 0;
}
@media (max-width: 767px) {
  .jackpotContent {
    top: 25%;
    font-size: 1.25rem;
  }
}
</style>
