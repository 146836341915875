<template>
  <b-container fluid class="pb-5">
    <b-container class="homePartContent py-1">
      <div class="homeHistoryContainer py-2 my-5 mx-3">
        <div class="homeHistoryContent m-3">
          <div class="homeHistoryText my-2">{{ $t("HomeHistoryCompany.AboutPlaytech") }}</div>
          <i18n-t tag="div" keypath="HomeHistoryCompany.DescText" class="textDesc">
            <template #newline>
              <div class="my-2"></div>
            </template>
          </i18n-t>
        </div>
      </div>
    </b-container>
  </b-container>
</template>

<style scoped>
.homeHistoryContainer {
  background: linear-gradient(180deg, #6a91d2 0%, #28356a 100%);
  border: 3px solid #c1d7fb;
  border-radius: 10px;
}
.homeHistoryContent {
  color: #ffffff;
  text-align: left;
  font-size: 0.9rem;
}
.homeHistoryText {
  font-size: 1.1rem;
}
</style>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    checkIsLoadMemberBank() {
      return this.$store.getters["member/getOnLoadMemberBankStatus"];
    },
  },
  components: {},
  methods: {},
};
</script>
