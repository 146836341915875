import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";

export interface GamesStateInterface {
	gameLists: GamesListResponse[];
}

function state(): GamesStateInterface {
	return {
		gameLists: [],
	};
}

export default state;
