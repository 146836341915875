<template>
  <b-row align-h="center" class="navContainer d-none d-md-flex">
    <div class="navContent">
      <nav>
        <NavBarLink> </NavBarLink>
      </nav>
    </div>
  </b-row>
</template>

<style scoped>
nav {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-right: 3rem;
  padding: 20px 30px 14px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

.navContainer {
  width: 100%;
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: end;
}

@media (max-width: 991px) {
  nav {
    padding: 20px 0px 14px;
  }
  .navContent {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 767px) {
  .navContent {
    width: 100%;
  }

  nav {
    padding: 10px 0px 2px;
  }
}
</style>
<style>
nav a.router-link-exact-active > .navLink,
nav a.router-link-active > .navLink {
  color: #298fc2 !important;
}
</style>
<script>
import NavBarLink from "@/components/content/Header/HeaderNavLink";
export default {
  components: {
    NavBarLink,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
