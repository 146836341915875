<template>
	<b-modal
		id="withdrawal-modal"
		class="myaccount-modal"
		v-model="modalShow"
		size="lg"
		hide-footer
		centered
		cancel-disabled
		data-backdrop="static"
		:title="$t('WithdrawalModal.Withdraw')"
		@show="WithrawalModalShow"
	>
		<b-container class="overlay" :class="{ 'd-none': !disabledWithdrawalForm }">
			<div class="d-flex justify-content-center align-items-center w-100 h-100">
				<div class="h1">{{ $t("WithdrawalModal.WinoverExist") }}</div>
			</div>
		</b-container>
		<b-container>
			<b-col class="text-dark pb-2">
				<b-row>
					<b-form name="withdrawalForm">
						<b-col style="text-align-last: start">
							<b-input-group class="mt-3 justify-content-start">
								<b-form-group v-bind:label="$t('WithdrawalModal.Currentbalance')" class="text-dark mb-0">
									<h3 class="text-nowrap">MYR {{ getCurrentBalance }}</h3>
									<!-- <label class="text-success" style="text-decoration: underline">{{
                      $t("WithdrawalModal.Withdraw")
                    }}</label> -->
								</b-form-group>
							</b-input-group>
						</b-col>

						<b-row cols="2">
							<b-col>
								<b-input-group class="mt-3">
									<b-form-input
										id="withdrawal-amount"
										v-model="withdrawalForm.amount"
										type="number"
										v-bind:placeholder="$t('WithdrawalModal.withdrawalAmount')"
										required
										:disabled="disabledWithdrawalForm"
									></b-form-input>
								</b-input-group>
								<label class="text-start"
									>{{ $t("WithdrawalModal.Min", { withdrawalMin: this.withdrawalMin }) }} /
									{{ $t("WithdrawalModal.Max", { withdrawalMax: this.withdrawalMax }) }}
								</label>
								<div class="input-errors" v-for="(error, index) of v$.withdrawalForm.amount.$errors" :key="index">
									<div class="error-msg">{{ error.$message }}</div>
								</div>
							</b-col>

							<b-col>
								<b-input-group class="mt-3">
									<b-form-input
										id="withdrawal-bank"
										v-model="memberBank.bankName"
										type="text"
										v-bind:placeholder="$t('WithdrawalModal.accountBank')"
										required
										disabled
									></b-form-input>
								</b-input-group>
							</b-col>

							<b-col>
								<b-input-group class="mt-3">
									<b-form-input
										id="withdrawal-accountname"
										v-model="memberBank.accountHolder"
										type="text"
										v-bind:placeholder="$t('WithdrawalModal.accountName')"
										required
										disabled
									></b-form-input>
								</b-input-group>
							</b-col>

							<b-col>
								<b-input-group class="mt-3">
									<b-form-input
										id="withdrawal-accountnumber"
										v-model="memberBank.accountNumber"
										type="text"
										v-bind:placeholder="$t('WithdrawalModal.accountNumber')"
										required
										disabled
									></b-form-input>
								</b-input-group>
							</b-col>
						</b-row>
					</b-form>
				</b-row>

				<b-row class="justify-content-center my-3 mt-5" v-if="!disabledWithdrawalForm">
					<b-col cols="6">
						<b-button variant="main" inline @click="submitWithdrawalForm" class="w-100" id="textHoverWhite">{{
							$t("WithdrawalModal.Withdraw")
						}}</b-button>
						<b-button variant="none" class="text-dark w-100" data-bs-dismiss="modal">{{
							$t("WithdrawalModal.Cancel")
						}}</b-button>
					</b-col>
				</b-row>
			</b-col>
		</b-container>
	</b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minValue, maxValue } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			withdrawalForm: {
				amount: {
					required: helpers.withMessage(() => this.$t("WithdrawalModal.AmountRequired"), required),
					minValue: helpers.withMessage(
						({ $params }) => this.$t("WithdrawalModal.AmountMin", { minValue: this.currency + $params.min }),
						minValue(this.withdrawalMin)
					),
					maxValue: helpers.withMessage(
						({ $params }) => this.$t("WithdrawalModal.AmountMax", { maxValue: this.currency + $params.max }),
						maxValue(this.withdrawalMax)
					),
				},
			},
		};
	},
	data() {
		return {
			withdrawalForm: {
				amount: "",
			},
			memberBank: {
				bankName: "",
				bankCode: "",
				accountHolder: "",
				accountNumber: "",
			},
			currency: "RM",
			withdrawalMin: "50",
			withdrawalMax: "50000",
			withdrawalDailyLimit: 5,
			modalShow: false,
			disabledWithdrawalForm: false,
		};
	},
	computed: {
		getMemberID() {
			if (this.memberID) {
				return this.memberID;
			} else if (this.$store.getters["identityServer/getLoginId"]) {
				return "+60" + this.$store.getters["identityServer/getLoginId"];
			} else {
				return this.$t("HeaderLogin.Player");
			}
		},
		getCurrentBalance() {
			return this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
		},
		checkMemberBank() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
	},
	components: {},
	methods: {
		async submitWithdrawalForm() {
			const toast = useToast();
			let toastPosition = this.isMobile ? "bottom-center" : "top-center";
			const result = await this.v$.withdrawalForm.$validate();
			if (!result) {
				return;
			}
			if (this.getCurrentBalance < parseFloat(this.withdrawalForm.amount)) {
				toast.error(this.$t("WithdrawalModal.InsufficientBalance"), { position: toastPosition, duration: 5000 });
				return;
			}
			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});

			await this.$store
				.dispatch("payment/addWithdrawal", this.withdrawalForm)
				.then(() => {
					this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false });
					loader.hide();
					toast.success(this.$t("WithdrawalModal.WithdrawalSubmitted"), { position: toastPosition });
					this.hideModal();
				})
				.catch((err) => {
					loader.hide();
					if (err.response != null && err.response.data != null && err.response.data.title == "ReachDailyLimit") {
						toast.error(this.$t("WithdrawalModal.ReachDailyLimit"), { position: toastPosition, duration: 5000 });
					} else if (
						err.response != null &&
						err.response.data != null &&
						err.response.data.title == "AllowOneActiveWithdrawal"
					) {
						toast.error(this.$t("WithdrawalModal.AllowOneActiveWithdrawal"), {
							position: toastPosition,
							duration: 5000,
						});
					} else {
						toast.error(this.$t("WithdrawalModal.WithdrawalError"), { position: toastPosition });
					}
				});
		},
		hideModal() {
			this.modalShow = false;
		},
		async getSettingWithdrawalMinMax() {
			await this.$store.dispatch("globalSetting/fetchGlobalSetting", { remark: "" }).then(() => {
				const withdrawalMinMaxSetting = this.$store.getters["globalSetting/getGlobalSetting"];
				if (withdrawalMinMaxSetting.length !== 0) {
					const withdrawalMinSetting = withdrawalMinMaxSetting.find((x) => x.remarks == "WithdrawalMin");
					const withdrawalMaxSetting = withdrawalMinMaxSetting.find((x) => x.remarks == "WithdrawalMax");
					const withdrawalLimitSetting = withdrawalMinMaxSetting.find((x) => x.remarks == "WithdrawalDailyLimit");
					if (withdrawalMinSetting !== undefined) {
						if (withdrawalMinSetting.status) {
							this.withdrawalMin = withdrawalMinSetting.content;
						}
					}
					if (withdrawalMaxSetting !== undefined) {
						if (withdrawalMaxSetting.status) {
							this.withdrawalMax = withdrawalMaxSetting.content;
						}
					}
					if (withdrawalLimitSetting !== undefined) {
						if (withdrawalLimitSetting.status) {
							this.withdrawalDailyLimit = withdrawalLimitSetting.content;
						}
					}
				}
			});
		},
		WithrawalModalShow() {
			this.getSettingWithdrawalMinMax();
			let memberBankStatus = this.checkMemberBank;
			if (memberBankStatus) {
				this.memberBank = this.$store.getters["member/getMemberBank"];
			}
			this.fetchMemberWinover().then(() => {
				this.checkWinover();
			});
		},
		async checkWinover() {
			await this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false });
			const latestBalance = this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
			const memberWinover = this.$store.getters["memberAccount/getMemberWinover"];
			if (memberWinover.winoverBalances.length > 0) {
				const latestWinover = memberWinover.winoverBalances[0].winover;
				const group = memberWinover.winoverBalances[0].category;
				const lockdown = memberWinover.winoverBalances[0].lockdown;
				if (latestWinover > 0) {
					this.disabledWithdrawalForm = true;
					await this.getVedorCategoryList(latestBalance, group, latestWinover, lockdown);
				} else {
					this.disabledWithdrawalForm = false;
				}
			} else {
				this.disabledWithdrawalForm = false;
			}
		},
		async getVedorCategoryList(latestBalance, group, latestWinover, lockdown) {
			await this.$store.dispatch("vendors/fetchCategoryList").then(() => {
				const vendorCategory = this.$store.getters["vendors/getCategory"];
				this.disabledWithdrawalForm = true;
				if (latestBalance <= vendorCategory.resetThreshold) {
					this.resetWinover(group, latestWinover, lockdown);
					this.fetchMemberWinover();
				}
			});
		},
		async resetWinover(group, latestWinover, lockdown) {
			await this.$store.dispatch("memberAccount/resetWinover", {
				group: group,
				winover: latestWinover,
				lockdown: lockdown,
			});
		},
		async fetchMemberWinover() {
			await this.$store
				.dispatch("memberAccount/fetchMemberWinover", { loginId: this.getLoginId, isFromDB: true })
				.then(() => {
					this.disabledWithdrawalForm = false;
				});
		},
	},
};
</script>
