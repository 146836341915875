import { GamesStateInterface } from "./state";
import { MutationTree } from "vuex";
import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";

export enum GamesMutations {
	SET_GAMES_LIST = "SET_GAMES_LIST",
}

export const mutations: MutationTree<GamesStateInterface> = {
	[GamesMutations.SET_GAMES_LIST](state, payload: GamesListResponse[]) {
		state.gameLists = payload;
	},
};
