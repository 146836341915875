<template>
  <b-modal
    id="signup-modal"
    class="default-modal"
    v-model="modalShow"
    size="md"
    hide-footer
    centered
    cancel-disabled
    data-backdrop="static"
    :title="$t('SignUpModal.SignUp')"
    @show="resetData"
  >
    <b-container class="px-5" v-if="registerContainer">
      <b-row>
        <b-col cols="12">
          <b-form>
            <b-input-group class="mt-3" prepend="+60">
              <b-form-input
                v-model="signupForm.phoneNo"
                :placeholder="$t('SignUpModal.PhoneNumber')"
                type="text"
                required
                inputmode="numeric"
              >
              </b-form-input>
            </b-input-group>
            <div class="input-errors" v-for="(error, index) of v$.signupForm.phoneNo.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

            <b-input-group class="mt-3">
              <b-form-input
                v-model="signupForm.password"
                :placeholder="$t('SignUpModal.Password')"
                :type="passwordFieldType"
                required
              >
              </b-form-input>
              <b-input-group-append>
                <b-button squared variant="light" class="text-dark show-hide" @click="changePasswordType">{{
                  $t("SignUpModal." + this.showHideText)
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="input-errors" v-for="(error, index) of v$.signupForm.password.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

            <b-input-group class="mt-3">
              <b-form-input
                v-model="signupForm.confirmPassword"
                :placeholder="$t('SignUpModal.ConfirmPassword')"
                :type="confirmPasswordFieldType"
                required
              ></b-form-input>
              <b-input-group-append>
                <b-button squared variant="light" class="text-dark show-hide" @click="changeConfirmPasswordType">{{
                  $t("SignUpModal." + this.showHideCfText)
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="input-errors" v-for="(error, index) of v$.signupForm.confirmPassword.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

            <b-input-group class="mt-3">
              <b-form-input
                v-model="signupForm.referralCode"
                :placeholder="$t('SignUpModal.ReferralCode')"
                type="text"
              ></b-form-input>
            </b-input-group>
            <div class="input-errors" v-for="(error, index) of v$.signupForm.referralCode.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mx-0 my-3">
        <b-button variant="main" block @click="submitRegisterForm">{{ $t("SignUpModal.SignUp") }}</b-button>
        <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" :value="true" :unchecked-value="false">
          <label variant="white"
            >{{ $t("SignUpModal.Desc1") }}
            <router-link class="" :to="'/terms'" target="_blank">{{ $t("SignUpModal.TermsandConditions") }}</router-link
            >{{ $t("SignUpModal.Desc2")
            }}<router-link class="" :to="'/privacypolicy'" target="_blank"
              >{{ $t("SignUpModal.PrivacyPolicy") }}.</router-link
            ></label
          >
        </b-form-checkbox>
        <div class="input-errors" v-for="(error, index) of v$.status.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </b-row>
    </b-container>
    <b-container class="px-5" v-if="tacContainer">
      <b-row class="verifieContent">
        <b-col>
          <b-form>
            <b-col class="mt-3">
              <h5 class="verifieType">{{ $t("SignUpModal.VerificationNumber") }}</h5>
            </b-col>
            <b-col class="mt-3 px-3">
              <b-form-input
                class="text-center"
                v-model="tacCode"
                :placeholder="$t('SignUpModal.TACCode')"
                type="number"
                required
              ></b-form-input>
            </b-col>
            <b-col class="my-4">
              <b-button variant="main" block @click="requestTAC">{{ $t("SignUpModal.Resend") }}</b-button>
            </b-col>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mx-0 mb-3 mt-5">
        <b-button variant="main" block @click="submitTAC">{{ $t("SignUpModal.ConfirmTAC") }}</b-button>
      </b-row>
    </b-container>
  </b-modal>
</template>

<style scoped>
.verifieType,
.verifieText {
  color: #000000;
}
.verifieResendBtn {
  color: #3e0d0c;
}
.verifieContent {
  background: #f0f0f0 !important;
  border-radius: 28px;
  text-align: center;
}
</style>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, numeric, maxLength, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      signupForm: {
        phoneNo: {
          required: helpers.withMessage(() => this.$t("SignUpModal.PhoneRequired"), required),
          numeric: helpers.withMessage(() => this.$t("SignUpModal.PhoneNumeric"), numeric),
          minLength: helpers.withMessage(
            ({ $params }) => this.$t("SignUpModal.PhoneMin", { minLength: $params.min }),
            minLength(9)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) => this.$t("SignUpModal.PhoneMax", { maxLength: $params.max }),
            maxLength(10)
          ),
        },
        password: {
          required: helpers.withMessage(() => this.$t("SignUpModal.PasswordRequired"), required),
          minLength: helpers.withMessage(
            ({ $params }) => this.$t("SignUpModal.PasswordMin", { minLength: $params.min }),
            minLength(6)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) => this.$t("SignUpModal.PasswordMax", { maxLength: $params.max }),
            maxLength(16)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage(() => this.$t("SignUpModal.ConfirmPasswordRequired"), required),
          minLength: helpers.withMessage(
            ({ $params }) => this.$t("SignUpModal.PasswordMin", { minLength: $params.min }),
            minLength(6)
          ),
          maxLength: helpers.withMessage(
            ({ $params }) => this.$t("SignUpModal.PasswordMax", { maxLength: $params.max }),
            maxLength(16)
          ),
          sameAsPassword: helpers.withMessage(
            () => this.$t("SignUpModal.NotSamePassword"),
            sameAs(this.signupForm.password)
          ),
        },
        referralCode: {},
      },
      status: {
        isChecked: helpers.withMessage(() => this.$t("SignUpModal.AcknowledgeUncheck"), sameAs(true)),
      },
    };
  },
  data() {
    return {
      signupForm: {
        phoneNo: "",
        password: "",
        confirmPassword: "",
        referralCode: "",
        countryCode: "+60",
      },
      tacCode: null,
      phoneMin: 9,
      phoneMax: 10,
      passwordMin: 6,
      passwordMax: 16,
      passwordFieldType: "password",
      confirmPasswordFieldType: "password",
      showHideText: "Show",
      showHideCfText: "Show",
      isMobile: this.$isMobile(),
      modalShow: false,
      status: false,
      registerContainer: true,
      tacContainer: false,
      toastPosition: this.$isMobile() ? "bottom-center" : "top-center",
    };
  },
  computed: {},
  components: {},
  methods: {
    changePasswordType() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.showHideText = this.showHideText == "Show" ? "Hide" : "Show";
    },
    changeConfirmPasswordType() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === "password" ? "text" : "password";
      this.showHideCfText = this.showHideCfText == "Show" ? "Hide" : "Show";
    },
    hideModal() {
      this.modalShow = false;
    },
    async requestTAC() {
      await this.$store
        .dispatch("register/requestTAC", this.signupForm)
        .then(() => {
          this.toast.success(this.$t("SignUpModal.RequestTACSuccess"), { position: this.toastPosition });
        })
        .catch(() => {
          this.toast.error(this.$t("SignUpModal.RequestTACFailed"), { position: this.toastPosition });
        });
    },
    async submitRegisterForm() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      await this.$store
        .dispatch("register/registerAccount", this.signupForm)
        .then(() => {
          loader.hide();
          this.registerContainer = false;
          this.tacContainer = true;
        })
        .catch((err) => {
          loader.hide();
          let toastMsg = "SignUpModal.RegisterError";
          if (err.message == "InvalidReferralCode") {
            toastMsg = "SignUpModal.InvalidReferralCode";
          }
          this.toast.error(this.$t(toastMsg), { position: this.toastPosition });
        });
    },
    async submitTAC() {
      await this.$store
        .dispatch("register/verifyTAC", { phoneNo: this.signupForm.phoneNo, token: this.tacCode })
        .then(() => {
          this.toast.success(this.$t("SignUpModal.RegisterSuccess"), { position: this.toastPosition });
          this.loginAfterRegister();
          this.hideModal();
          this.registerContainer = true;
          this.tacContainer = false;
        })
        .catch(() => {
          this.toast.error(this.$t("SignUpModal.VerifyTACError"), { position: this.toastPosition });
        });
    },
    async loginAfterRegister() {
      await this.$store.dispatch("identityServer/fetchLoginToken", this.signupForm).catch(() => {
        this.toast.error(this.$t("LoginModal.LoginError"), { position: this.toastPosition });
      });
    },
    resetData() {
      this.v$.$reset();
      this.signupForm.phoneNo = "";
      this.signupForm.password = "";
      this.signupForm.confirmPassword = "";
      this.signupForm.referralCode = "";
      this.signupForm.countryCode = "+60";
      this.tacCode = null;
      this.status = false;
    },
  },
};
</script>
