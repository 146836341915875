export default {
  LIVECASINO: "LIVE CASINO",
  PlayNow: "Main Sekarang",
  LaunchGameError: "Permainan pelancaran gagal, sila hubungi Khidmat Pelanggan kami",
  Seemore: "Lihat Lagi",
  HOTGAMES: "PERMAINAN TOP SLOT",
  moreGames: "Banyak Lagi",
  Play: "BERMAIN",
  GameMaintenance: "Permainan sedang diselenggara. Sila cuba sebentar lagi.",
};
