<template>
  <b-container fluid class="px-0">
    <b-col class="position-relative">
      <base-image :imgSrc="homeCasinoImage" mode="bannerMax" :isFullUrl="false" />
      <b-col class="position-absolute liveCasinoContent">
        <template v-if="isLogin">
          <Carousel
            id="livecasinoCarousel"
            class="bannerMax h-100"
            :autoplay="3000"
            :wrap-around="true"
            pause-autoplay-on-hover
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <slide v-for="livecasinoImgItem in homeLiveCasinoImgList" :key="livecasinoImgItem" class="h-100">
              <div class="livecasinoContainer h-100 w-100" @click="startPlay()">
                <base-image :imgSrc="hotGameImagePath + livecasinoImgItem.imgUrl" mode="bannerMax" :isFullUrl="false" />
              </div>
            </slide>
            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </template>

        <template v-else>
          <Carousel
            id="livecasinoCarousel"
            class="bannerMax h-100"
            :autoplay="3000"
            :wrap-around="true"
            pause-autoplay-on-hover
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <slide v-for="livecasinoImgItem in homeLiveCasinoImgList" :key="livecasinoImgItem" class="h-100">
              <div class="livecasinoContainer h-100 w-100" v-b-modal="'login-modal'">
                <base-image :imgSrc="hotGameImagePath + livecasinoImgItem.imgUrl" mode="bannerMax" :isFullUrl="false" />
              </div>
            </slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </template>
      </b-col>
    </b-col>
  </b-container>
</template>
<style scoped>
.logoMax {
  background: #fff;
  border-radius: 10px;
  height: auto;
  width: 100%;
}
.livecasinoContainer {
  padding: 0rem 0.5rem;
  cursor: pointer;
}
.liveCasinoContent {
  width: 55.5vw;
  bottom: 30.5%;
  left: 8%;
  background: rgba(39, 50, 85, 0.5);
  border: 10px solid rgba(39, 50, 85, 0.5);
  backdrop-filter: blur(64px);
  border-radius: 12px;
}
</style>
<style>
.liveCasinoContent .carousel__prev,
.liveCasinoContent .carousel__next {
  display: none;
}
.liveCasinoContent .carousel__viewport {
  background: rgba(39, 50, 85, 0.5) !important;
  height: 100%;
}

.liveCasinoContent .carousel__pagination {
  margin: 0px;
  bottom: -3rem;
}

.liveCasinoContent .carousel__pagination-button {
  width: 5px;
  height: 12px;
  background: #d9d9d9;
}

.liveCasinoContent .carousel__pagination-button.carousel__pagination-button--active {
  background: #c99e53;
}

.liveCasinoContent .carousel__slide,
.liveCasinoContent .carousel__track {
  height: 100%;
}

@media (max-width: 767px) {
  .livecasinoContainer {
    padding: 0rem 0.2rem;
  }
  .liveCasinoContent {
    width: 61.5vw !important;
    bottom: 17% !important;
    border: 5px solid rgba(255, 255, 255, 0.1) !important;
  }

  .liveCasinoContent .carousel__pagination-button {
    width: 25%;
    height: 50%;
    border-radius: 50%;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .liveCasinoContent .carousel__pagination {
    bottom: -2rem;
  }
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  data() {
    return {
      homeCasinoImage: "playtech/assets/images/Home/Playtech_banner.png",
      homeCasinoLogo: "playtech/assets/images/Home/cmd368.png",
      hotGameImagePath: "playtech/assets/images/Home/HotSlideBanner/",
      homeLiveCasinoImgList: [
        {
          imgUrl: "HiLo.png",
        },
        {
          imgUrl: "Quantun.png",
        },
        {
          imgUrl: "Roulette.png",
        },
        {
          imgUrl: "Sicbo.png",
        },
        {
          imgUrl: "dragonTiger.png",
        },
        {
          imgUrl: "grandBaccarat.png",
        },
      ],
      isFirstLoad: true,
      isSpin: false,
      settings: {
        itemsToShow: 3,
        snapAlign: "start",
      },
      breakpoints: {
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  methods: {
    async startPlay() {
      let loader = this.$loading.show();
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", { GameType: GameType.LIVE_CASINO, Lang: localStorage.getItem("locale") })
        .then((url) => {
          loader.hide();
          window.open(url, "_blank");
        })
        .catch(() => {
          loader.hide();
          let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
          this.toast.error(this.$t("HomeLiveCasino.LaunchGameError"), { position: toastPosition });
        });
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
  },
};
</script>
