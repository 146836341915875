export default {
  WelcomVIPDesc: "Monthly Rank",
  Red: "Red",
  Silver: "Silver",
  Gold: "Gold",
  Platinum: "Platinum",
  Diamond: "Diamond",
  Royal: "Royal",
  Regular: "Regular",
  DepositPertama: "Deposit Pertama",
  firstDeposit: "First Deposit",
  MinDeposit: "Min. Deposit",
  VIPPrivileges: "VIP PRIVILEGES",
  giftTreats: "GIFTS & TREATS",
  tierUpgradeBonus: "Tier Upgrade Bonus",
  birthdayBonus: "Birthday Bonus",
  dailyRescueBonus: "Daily Rescue Bonus",
  weeklyRescueBonus: "Weekly Rescue Bonus",
  designatedAccountManager: "Designated Account Manager",
  SpecialRebate: "SPECIAL REBATE",
  SlotsRebate: "Slots Rebate",
  LiveCasinoRebate: "Live Casino Rebate",
  SportsRebate: "Sports Rebate",
  tierUpgradeRequirement: "TIER UPGRADE REQUIREMENT",
  minDepositRotation: "Minimum Deposit Within a month",
  tierRetentionRequirement: "TIER RETENTION REQUIREMENT",
  retentionTotalDeposit: "Accumulated Deposit Within 3 months",
  membershipRotation: "Membership Renewal",
  minDeposit: "Minimum Deposit",
  within_DAILY: "Dalam 1 Hari ",
  within_WEEKLY: "Dalam 1 Minggu ",
  within_MONTHLY: "Dalam 1 Bulan",
  within_QUARTERLY: "Dalam 3 Bulan",
  "within_HALF YEARLY": "Dalam 6 Bulan",
  within_YEARLY: "Dalam 1 Tahun",
  within_LIFETIME: "Dalam Seumum Hidup",
  Lifetime: "Lifetime",
  Quarterly: "Quarterly",
  VIPExclusive: "VIP Exclusive",
  Yes: "Ya",
  ONCE: "Sekali",
  ALWAYS: "Sentiasa",
  DAILY: "Harian",
  WEEKLY: "Mingguan",
  MONTHLY: "Bulanan",
  QUARTERLY: "Suku",
  "HALF YEARLY": "Setengah Tahun",
  YEARLY: "Tahunan",
  LIFETIME: "Seumur Hidup",
  CurrentStatus: "Status Semasa",
  memberVIPDesc: "VIP MEMBERSHIP LEVEL",
  FAQs: "FAQs",
  QuestionHowToBecomeAVIPMember: "Q: How to become a VIP member?",
  AnswerHowToBecomeAVIPMember:
    "A: You will need to deposit at least RM{0} within a single quarter ({1} months) to become a Silver Member.",
  QuestionHowManyLevel: "Q: How many levels are there for VIP program?",
  AnswerHowManyLevel:
    "A: There are a total of 6 levels. The VIP Program consists of Red, Silver, Gold, Platinum, Diamond and Black Gold tier levels.",
  QuestionHowToUpgradeNextLevel: "Q: How do I get upgraded to the next level ?",
  AnswerHowToUpgradeNextLevel:
    "A: You will have to deposit the minimum amount required by each tier into your account. For example, if you are currently a Red Member, you will have to deposit at least RM10,000 to become a Silver Member, and from Silver Member, you will need to deposit at least another RM30,000 to become a Gold Member.",
  QuestionHowlongMemberstatus: "Q: How long is my VIP Membership status valid for?",
  AnswerHowlongMemberstatus:
    "A: Once you are upgraded to the next level, your VIP Membership is valid for one year based on the hightest tier level archieved.",
  QuestionHowDoIRetainMembership: "Q: How do I retain my VIP membership status ?",
  AnswerHowDoIRetainMembership:
    "A: To retain your member tier status for the following year, you will need to deposit the required amount by the last quarter before the expiration date. For example, if your Gold Member status is valid from January - December, you will need to achieve a RM15,000 deposit by the final quarter of the year (October) to retain your existing level for the following year. However, Red and Silver level membership status is valid for lifetime.",
  QuestionIsThereAnnualFees: "Q: Is there an annual or monthly fees to be part of the VIP members?",
  AnswerIsThereAnnualFees:
    "A: No. This program was created to promote all loyal members who actively patronage our gaming experience since registered date.",
  ImportantNote: "Important Note: ",
  TermsConditions: "Terms & Conditions ",
  tnc1: "For the first time upgrade, members are required to provide all the necessary documents for verification purposes. After the verification is complete, the membership status will be updated into the system within 3 working days.",
  TheVerificationStepsAreAsFollows: "The verification steps are as follows:",
  tnc1stepI: "Verify Profile Details (Full name, Phone number and Email Address)",
  tnc1stepII: "Verify Game Account Activity (Betting Style, Deposit & Withdraw Activity)",
  tnc1ImportantNote:
    "Should there be any suspicious or abnormal activities during the 3-day verification period, the management reserves the right to reject the qualification to upgrade.",
  tnc2: "VIP Privilege can be enjoyed immediately after upgrading to the next tier level and is valid for one year based on the highest tier achieved.",
  tnc2ImportantNote:
    "In order to retain your membership status, members are required to achieve the same requirement within a single quarter.",
  tnc3: "All VIP status upgrade invitations will be sent out by Vava88 VIP Department.",
  tnc4: "Vava88 reserves the right to remove members’ account VIP Privilege, and to downgrade the VIP levels or even terminate any accounts it deems to have any suspicious or abnormal activities at any time without any prior notice.",
  tnc4ImportantNote: "Every membership status subject to downgrade based on the account activities.",
  tnc5: "All Vava88 management decisions are final.",
  welcomVIPDesc: "SUPREME VIP LOUNGE",
  welcomVIPDesc0: "Experience the true Supreme VIP treatment",
  welcomVIPDesc1: "BE A JOKER VIP",
  welcomVIPDesc2:
    "Introducing the Enhance VIP Progran, providing our devoted member at JOKER with elevated benefits. Beyond typical incentives, we present special promotions exclusively tailored for VIPs, along with bonus plays featuring the highest retuns. {newline} Moreover, our VIP members take precedence in all matters whether it's regrading deposits, whithrawals, or any game-related concerns, as their requets are handled with top priority.",
  welcomVIPDesc3: "IDENTITY VERIFICATION IS COMPULSORY FOR VIP UPGRADE",
  welcomVIPDesc4: "EARN REWARDS BY REFERRING!",
  welcomVIPDesc5:
    "Our referral initiative offers current VIP members additional perks for bringing friends and family into our VIP program. {newline} The JOKER Referral Program is designed tp provide existing members whith extra motivation for spreading the advantages of VIP membership. This is our way of expressing gratitude for your loyalty and your role in expanding the JOKER VIP community. Have a friend who'd love to join you in the VIP lounge? {newline} Don't wait - refer them today! Exciting rewards and bonuses are in store!",
  Welcome: "Welcome",
  NextLevel: "Next Level",
  ByInvitation: "By Invitation",
  Current: "Current",
  Prev: "Prev",
  Next: "Next",
  10000: "MYR 10000",
  50000: "MYR 50000",
  500000: "MYR 500000",
  100000: "MYR 100000",
  1000000: "MYR 1000000",
};
